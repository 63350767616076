import axios from "axios";
const API = axios.create({ baseURL: "https://ezshifa.com/emr/restapi/Web_Patient/RestController_WP.php?view" });
const token = localStorage.getItem("accessToken");
const projectId = "20207b18-eebe-48da-a65c-242c62014bef";
/* Delete API */
// const token = '123456'
/* Delete Vital API */
const baseurl = "https://ezshifa.com/emr/restapi/Web_Patient/RestController_WP.php?view"
// const baseurl = "https://dev.ezshifa.com/emr/restapi/Web_Patient/RestController_WP.php?view"

export const deleteVital = ({ id }) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=delete_vitals&id=${id}&jwt-token=${token}`, {
    headers: {
    },
  }))}

/* Delete Patient Report API */

export const deleteReport = ({ id }) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.delete(`${baseurl}=patient-reports-delete/${id}&jwt-token=${token}`, {
    headers: {
      // "x-access-token": token,
    },
  }))}

/* POST API */

/* Login API */

export const signIn = (credentials) =>
  axios.post(`${baseurl}=signin`, credentials, {
    // headers: { projectId: "20207b18-eebe-48da-a65c-242c62014bef" },
  });

/* Register API */

export const createPatient = (data) =>
  axios.post(`${baseurl}=create-web-patient`, data, {
    // headers: { projectId: "20207b18-eebe-48da-a65c-242c62014bef" },
  });

/* Store Appointment API */

export const storeAppointment = (data) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=store-appointment&jwt-token=${token}`, data, {
    // headers: { projectId: projectId },
  }))}

/* Add Vitals API */

export const addVitals = (data) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=create-vital&jwt-token=${token}`, data, {
    headers: {
      // "x-access-token": token,
    },
  }))}

/* Update Patient API */

export const updatePatient = ({ patientId }, data) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=update-patient-profile/${patientId}&jwt-token=${token}`, data, {
    // headers: { "x-access-token": token },
  }))}

/* Upload File API */

export const uploadFile = ({ fileType }, data) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=file-upload?fileType=${fileType}&jwt-token=${token}`, data, {
    headers: { "Content-Type": "multipart / form - data" },
  }))}

/* Upload Patient Report */

export const uploadReport = (data) =>  {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=patient-reports-store&jwt-token=${token}`, data, {
    // headers: { "x-access-token": token },
  }))}

/* API */

/* Recommended Doctors API */

export const recommendedDoctors = ({ userId , limit }) =>  {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(
    `${baseurl}=search-online-doctor&patientid=${userId}&jwt-token=${token}`,
    {
      // headers: { projectId: projectId },
    }
  ))}

/* Appointment Join API */

export const joinAppointment = ({ appId }) => {
  const token = localStorage.getItem("accessToken");
    return(
  API.get(`/api/doctor-appointment/appointment-join-notification/${appId}&jwt-token=${token}`, {
    // headers: { "x-access-token": token },
  }))}

/* Get Specialities API */

export const getSpecialities = () => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=specialities?pageLimit=10&pageno=1&jwt-token=${token}`)
    )}
/* Get Facilities API */

export const getFacilities = () => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.get(`${baseurl}=facilities?pageLimit=9&pageno=1&jwt-token=${token}`)
  )}
/* Get Appointments API */

export const getAppointments = ({ id }) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=get-patient-appointments/${id}&jwt-token=${token}`, {
    headers: {
      // projectId: projectId,
      // "x-access-token": token,
    },
  }))}
  // .catch(data => {if(data.message === 'Request failed with status code 401'){
  //     logoutnavi();
  // }});

/* Get Single Appointment API */

export const getSingleAppointment = ({ appointmentID }) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(
    `${baseurl}=get-appointment/${appointmentID}&jwt-token=${token}`,
    {
      headers: {
        // "x-access-token": token,
      },
    }
  ))}

  export const getOneAppointment = ({ appointmentID }) => {
    const token = localStorage.getItem("accessToken");
      return(
  axios.post(
    `${baseurl}=confirm-appointment&appointmentId=${appointmentID}&jwt-token=${token}`,
    {
      headers: {
        // "x-access-token": token,
      },
    }
  ))}

/* Get Patient Upcoming Appointments API */

export const getUpcomingAppointments = ({id}) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.get(
    `${baseurl}=get-patient-upcoming-appointment/${id}?timezone=Asia%2FKarachi&jwt-token=${token}`,
    {
      headers: {
        // projectId: projectId,
        // "x-access-token": token,
      },
    }
  ))}
  // .catch(resp =>
  //   {
  //   if(resp.message === "Request failed with status code 401"){}
  //   logoutnavi();   
  //   })

/* Get Facility Slots API */

export const getFacilitySlots = ({ doctorId, facilityId, appointmentDate }) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(
    `${baseurl}=facility-slots&doctorId=${doctorId}&facilityId=${facilityId}&date=${appointmentDate}&timezone=Asia/Karachi&jwt-token=${token}`,
    // { headers: { projectId: projectId } }
  ))}

/* Get Specific Doctor API */

export const getDoctor = ({ docId }) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=doctor/${docId}?timezone=Asia/Karachi&jwt-token=${token}`, {
    // headers: { projectId: projectId },
  }))}

/* Get Ambulance API */

export const getAmbulance = () =>
{
  const token = localStorage.getItem("accessToken");
    return(
  axios.get(
    `${baseurl}=get-ambulances?latitude=0&longitude=0&timezone=Asia%2FKarachi&jwt-token=${token}`
  ))}

/* Get Vitals API */

export const getVitals = ({ patientId, appointmentId }) =>
{
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(
    `${baseurl}=get-vitals/${patientId}/timezone=Asia%2FKarachi&jwt-token=${token}`,
    {
      headers: {
        // "x-access-token": token,
      },
    }
  ))}

/* Get Patient Profile Data API */

export const getPatient = ({ patientId }) =>
{
  const token = localStorage.getItem("accessToken");
    return(
  axios.get(`${baseurl}=patient-details/${patientId}&jwt-token=${token}`, {
    // headers: { "x-access-token": token },
  })
  .catch(resp => {
    if(resp.message === "Request failed with status code 401"){
      // logoutnavi();
    }}))}

/* Get Countries API */

export const getCountry = () => {
  const token = localStorage.getItem("accessToken");
    return( axios.get(`${baseurl}=get-countries&jwt-token=${token}`))}

/* Get States API */

export const getState = ({ countryId }) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.get(`${baseurl}=get-states/${countryId}&jwt-token=${token}`))}

/* Get Cities API */

export const getCity = ({ stateId }) =>{
  const token = localStorage.getItem("accessToken");
    return(
  axios.get(`${baseurl}=get-cities/${stateId}&jwt-token=${token}`))}

/* Get Search Query API */

export const searchQuery = ({ search, lat, long, type, cities, country }) =>
{
  const token = localStorage.getItem("accessToken");
    return(
  axios.get(
    `${baseurl}=search&string=${search}&jwt-token=${token}`
  ))}

/* Get Hint Data API */

export const getHintData = () => {
  const token = localStorage.getItem("accessToken");
    return( axios.get(`${baseurl}=getHintData&jwt-token=${token}`))}

/* Get Easypaisa Link API */

export const easyPaisa = (data) =>
  axios.post(
    `https://ezshifa.com/emr/billing/jazzcash_api.php`, data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  export const freepatient = (data) => {
    const token = localStorage.getItem("accessToken");
      return(
  axios.post(
    `${baseurl}=is_free_patient&jwt-token=${token}`, data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  ))}
  
/* Get CreditCard Link API */

export const creditCardpay = ({ appointmentId, patientId }) =>{
  const token = localStorage.getItem("accessToken");
    return(
  axios.get(
    `${baseurl}=get-cc-link/${appointmentId}/${patientId}?timezone=Asia/Karachi&jwt-token=${token}`,
    {
      //  headers: { "x-access-token": token } 
  }
  ))}

/* Get Patients Reports API */

export const getReports = ({ patientId }) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.get(`${baseurl}=patient-reports/${patientId}&jwt-token=${token}`, 
  {
    // headers: { "x-access-token": token },
  }))}

/* Get Patients By User API */

export const getPatientsByUser = () => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=patients-by-user&jwt-token=${token}`, {
    // headers: { "x-access-token": token },
  }).then(resp => {

    if(!resp.data){
    //  logoutnavi();
    }
}))}
/* Get Prescription By Appointment API */

export const getPrescription = ({ appointmentID }) => {
  const token = localStorage.getItem("accessToken");
    return(
   axios.get(`${baseurl}=get-prescription-by-appointment/${appointmentID}&jwt-token=${token}`, {
    // headers: { "x-access-token": token },
  }))}

/* Mark Favorite API */

export const markFavorite = ({ doctorId, patientId }) =>{
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=mark-favorite-doctor/${doctorId}/${patientId}&jwt-token=${token}`, {
    // headers: { "x-access-token": token },
  }))
}

/* Mark unFavorite API */

export const markUnfavorite = ({ doctorId , patientId }) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=mark-unfavorite-doctor/${doctorId}/${patientId}&jwt-token=${token}`, {
    // headers: { "x-access-token": token },
  })
  )
}

export const notifications = ({ patientId }) => {
const token = localStorage.getItem("accessToken");
  return(
    axios.post(`${baseurl}=get-user-notifications&patientId=${patientId}&jwt-token=${token}`, {})
    )
}

export const missednotifications = ({ appID }) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=missedAppointmentNotification&appointmentId=${appID}&jwt-token=${token}`, {
}))}

export const doctorRating = (data) => {
  const token = localStorage.getItem("accessToken");
    return(
  axios.post(`${baseurl}=add-doctor-rating&jwt-token=${token}`, data, {
}))}

export const allcountryCodes = () =>
axios.get('https://restcountries.com/v2/all');

export const checkfreeappt = (data) => {
  const token = localStorage.getItem("accessToken");
    return(
axios.post(
  `${baseurl}=get_free_appointments&jwt-token=${token}`, data,
  {
    headers: { "Content-Type": "multipart/form-data" },
  }
))}

export const forgotpas = (data) =>
axios.post(
  `${baseurl}=set_user_password&jwt-token=${token}`, data,
  {
    headers: { "Content-Type": "multipart/form-data" },
  }
);
export const sendotp = (data) =>
axios.post(
  `${baseurl}=send_otp_patient`, data,
  {
    headers: { "Content-Type": "multipart/form-data" },
  }
);
export const checknumber = (data) =>
axios.post(
  `${baseurl}=check_valid_user
  `, data,
  {
    headers: { "Content-Type": "multipart/form-data" },
  }
);