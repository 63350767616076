import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { checkfreeappt } from "../api";
import { useEffect } from "react";
import { useState } from "react";


export default function SidebarOption({ route, iconName, text }) {
  const location = useLocation();
  const currentRoute = location.pathname;
  const user = JSON.parse(localStorage.getItem('user')) 
  const [freeappt, setFreeappt] = useState({
    total:"" , remaining: localStorage.getItem('freeappts') , show: true
  });
  // const freeaptcheck = async () => {
  //   const response = await checkfreeappt({
  //     pid: user.id
  //   });
  //   setFreeappt({...freeappt, total:response.data.total_appt , remaining: response.data.rem_appt})
  // };
useEffect(() => {
  setFreeappt({remaining: localStorage.getItem('freeappts')})
  window.addEventListener('storage', () => {
    setFreeappt({remaining: localStorage.getItem('freeappts')})
})
} , [])

  return (
    <div
      className={
        currentRoute == route
          ? "group flex mt-2 w-56 py-3 items-center px-2 bg-white drop-shadow-md rounded-lg"
          : "group flex mt-2 w-56 py-3 items-center px-2"
      }
    >
      <div
        className={
          currentRoute == route
            ? `flex items-center justify-center text-white rounded-xl bg-purple w-8 h-8 ${text === "Free Appointments" && Number(freeappt.remaining) <= 0 && "text-slate-400"}`
            : `flex items-center justify-center text-purple rounded-xl bg-white w-8 h-8 ${text === "Free Appointments" && Number(freeappt.remaining) <= 0 && "text-slate-400" }`
        }
      >
        {iconName}
      </div>
      <p className="ml-3 font-normal">{text} 
      {/* {text === "Free Appointments" &&  Number(freeappt.remaining) > 0 && <span className="font-bold text-green-800 animate-pulse">{ Number(freeappt.remaining) >= 0 && freeappt.remaining } </span>} */}
      </p>
    </div>
  );
}
