import React, { Suspense, useEffect, useLayoutEffect, useMemo } from "react";
import "./App.css";
// import Login from "./screens/login";
import Dashboard from "./screens/dashboard";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
// import SearchDoc from "../src/screens/searchDoc";
// import PayFee from "../src/screens/payFee";
// import Prescription from "../src/screens/prescription";
// import HealthRecord from "../src/screens/healthRecord";
// import Appointments from "../src/screens/appointments";
// import VideoCall from "../src/screens/videoCall";
// import MyVitals from "../src/screens/myVitals";
// import WalkinAppointments from "../src/screens/walkinAppointment";
// import Ambulance from "./screens/ambulance";
// import Profile from "./screens/profile";
import MainPage from "./screens/mainPage";
// import DoctorChat from "./screens/docterChat";
// import Call from "./live-video-streaming";
// import FavDoc from "./screens/favDoctor.js";
// import Register from "./screens/register.js";
// import PrescriptionPdf from "./screens/prescriptionPdf";
// import TermsCondition from "./screens/termsCondition";
import { sidebarcontext } from "./screens/dashboard";
import { useState } from "react";
import Header from "./components/header";
import Geocode from "react-geocode";
// import Footer from "./components/Footer";
// import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import { load } from "./screens/constant";
import Swal from "sweetalert2";
import Webheader from "./components/Webheader";


const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default function App() {
  // const MainPage = React.lazy(() => import('./screens/mainPage'))
  const MyVitals = React.lazy(() => import('../src/screens/myVitals'))
  const Ambulance = React.lazy(() => import('./screens/ambulance'))
  const Appointments = React.lazy(() => import('../src/screens/appointments'))
  const Login = React.lazy(() => import('./screens/login'))
  const Register = React.lazy(() => import('./screens/register.js'))
  const FavDoc = React.lazy(() => import('./screens/favDoctor.js'))
  const Footer = React.lazy(() => import('./components/Footer'))
  const PrescriptionPdf = React.lazy(() => import('./screens/prescriptionPdf'))
  const DoctorChat = React.lazy(() => import('./screens/docterChat'))
  const Profile = React.lazy(() => import('./screens/profile'))
  const HealthRecord = React.lazy(() => import('../src/screens/healthRecord'))
  const Prescription = React.lazy(() => import('../src/screens/prescription'))
  const VideoCall = React.lazy(() => import('../src/screens/videoCall'))
  const WalkinAppointments = React.lazy(() => import('../src/screens/walkinAppointment'))
  const SearchDoc = React.lazy(() => import('../src/screens/searchDoc'))
  const PayFee = React.lazy(() => import('../src/screens/payFee'))
  const TermsCondition = React.lazy(() => import('./screens/termsCondition'))
  const ForgotPasswordPage = React.lazy(() => import('./screens/ForgotPassword'))

  const token = localStorage.getItem("accessToken");
  const [sidebar , setSidebar] = useState(false)
  const [DocId , setDoicId] = useState(0)
  const [Docname , setDocName] = useState("")
  const [gend , setGender] = useState("")
  const [cities, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [rating, setRating] = useState({open :false , appointID: 0 , patientID: 0, doctorID: 0 });
  const location = useLocation();

  Geocode.setApiKey("AIzaSyDRojKVNnXAK6Gsgo6xF_ptX9etpa3LVWs");
  Geocode.setLanguage("en");
  Geocode.setRegion("es");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();
  Geocode.fromLatLng().then(
    (response) => {
      let city, state, country;
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (
          let j = 0;
          j < response.results[0].address_components[i].types.length;
          j++
        ) {
          switch (response.results[0].address_components[i].types[j]) {
            case "locality":
              city = response.results[0].address_components[i].long_name;
              break;
            case "administrative_area_level_1":
              state = response.results[0].address_components[i].long_name;
              break;
            case "country":
              country = response.results[0].address_components[i].long_name;
              break;
          }
        }
      }
      setCity(city);
      setCountry(country);
    },
    (error) => {
      console.error(error);
    }
  );  
  return (
    <div>   
    <Webheader/>
      <sidebarcontext.Provider value={{sidebar , setSidebar , DocId , setDoicId , Docname , setDocName , gend , setGender , rating, setRating}}>
      <Header searchCity={setCity} searchCountry={setCountry} 
      name={location.pathname.includes("main") ? "Dashboard" : location.pathname.includes("ent/") ? "Book Appointment" :
      location.pathname.includes("orgotpass") ? "Forgot Password" : 
      location.pathname.includes("fav") ? "Favourite Doctor" : 
       location.pathname.includes("Call/") ? "Video Call"
      : location.pathname.includes("Chat") ? "Doctor Chat" : location.pathname.includes("my")
       || location.pathname.includes("health") ? location.pathname.slice(1).charAt(0).toUpperCase()
        + location.pathname.slice(2).replace(/([a-z])([A-Z])/g, '$1 $2')
         : location.pathname.slice(1).charAt(0).toUpperCase() + location.pathname.slice(2)}
        //  iconName={location.pathname.includes("main") && <MedicalServicesOutlinedIcon/>}
         />
      <Wrapper>
        <Routes>
          <Route path="/login" element={<Suspense fallback={load}><Login /></Suspense>} />
          <Route path='/forgotpass/:number' element={<Suspense fallback={load}><ForgotPasswordPage /></Suspense>} />
          <Route path="/register" element={<Suspense fallback={load}> <Register /> </Suspense>} />
          <Route path="/prescriptionpdf/:id" element={<Suspense fallback={load}><PrescriptionPdf /></Suspense>} />
          <Route path="/terms-condition" element={<Suspense fallback={load}><TermsCondition /></Suspense>} />
          <Route path="/" element={<Dashboard />}>
            <Route path="/" element={<Navigate replace to="/login" />} /> 
            <Route path="/topDoctors" element={<Suspense fallback={load}><SearchDoc /></Suspense>} />
            <Route path="/bookAppointment/:id" element={<Suspense fallback={load}><WalkinAppointments /></Suspense>}/>
            <Route path="/payFee/:id" element={<Suspense fallback={load}><PayFee /></Suspense>} />
            <Route path="/myAppointments" element={<Suspense fallback={load}><Appointments /></Suspense>}/>
            <Route path="/videoCall/:id/:appId" element={<Suspense fallback={load}><VideoCall /></Suspense>} />
            <Route path="/myVitals" element={<Suspense fallback={load}><MyVitals /></Suspense>} />
            <Route path="/ambulance" element={<Suspense fallback={load}><Ambulance /></Suspense>} />
            <Route path="/profile" element={<Suspense fallback={load}><Profile /></Suspense>} />
            <Route path="/main" element={<MainPage />} />
            <Route path="/docChat" element={<Suspense fallback={load}><DoctorChat /></Suspense>} />
            <Route path="/healthRecord" element={<Suspense fallback={load}><HealthRecord /></Suspense>} />
            <Route path="/prescription/:id" element={<Suspense fallback={load}><Prescription /></Suspense>} />
            <Route path="/favDoc" element={<Suspense fallback={load}><FavDoc /></Suspense>} />
          </Route>
        </Routes>
      </Wrapper>
      </sidebarcontext.Provider>
      <Suspense fallback={load}><Footer/></Suspense>
    </div>
  );
}
