import React from 'react'
import UpdateIcon from '@mui/icons-material/Update';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import SendIcon from '@mui/icons-material/Send';
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";


function Webheader() {
  return (
    <div className='bg-[#982A8F]'>
    <header className="flex justify-between items-center max-lg:flex-wrap max-lg:justify-center text-white gap-10 max-lg:gap-4 px-4 max-w-6xl mx-auto py-2">
    {/* Left side */}
    <div className="flex items-center max-lg:justify-center max-lg:flex-wrap">
    <div className='flex items-center'>
      <div className="mr-2 bg-slate-50 text-black hover:text-white rounded-full flex justify-center items-center p-1 hover:bg-blue-600 transition-all duration-300">
        <UpdateIcon fontSize='inherit' className='w-3'/>
      </div>
      <div className="mr-4">
        <h3 className="text-sm font-semibold">Mon-Sat 09:00 AM - 09:00 PM</h3>
      </div>
      </div>
      <div className='flex items-center'>
      <div className="mr-2 bg-slate-50 text-black hover:text-white rounded-full flex justify-center items-center p-1 hover:bg-blue-600 transition-all duration-300">
        <PhoneInTalkIcon fontSize='inherit' className='w-3'/>
      </div>
      <a href="tel:+923005018328" className="mr-4 cursor-pointer">
       <p className="text-sm font-semibold"> Call Us: +92 300 5018328</p>
      </a>
      </div>
      <div className='flex items-center'>
      <div className="mr-2 bg-slate-50 text-black hover:text-white rounded-full flex justify-center items-center p-1 hover:bg-blue-600 transition-all duration-300">
        <SendIcon fontSize='inherit' className='w-3'/>
      </div>
      <a href="mailto:info@ezshifa.com" className="">
        <h3 className="text-sm font-semibold">info@ezshifa.com</h3>
      </a>
      </div>
    </div>

    {/* Right side */}
    <div className="flex items-center">
    <li className=" flex justify-start items-center">
            <a href="https://www.facebook.com/ezshifa/" target="_blank" className="mr-3 text-base bg-white text-blue-500 hover:text-white transition-all duration-300 hover:bg-blue-500 cursor-pointer p-1 flex items-center rounded-full">
                  <FacebookIcon fontSize="inherit" />
                </a>
                <a href="https://twitter.com/ezshifa/" target="_blank" className="mr-3 text-base bg-white text-sky-500 hover:text-white transition-all duration-300 hover:bg-blue-500 cursor-pointer p-1 flex items-center rounded-full">
                  <TwitterIcon fontSize="inherit"/>
                </a>
                <a href="https://www.instagram.com/ezshifa/" target="_blank" className="mr-3 text-base bg-white text-orange-500 hover:text-white transition-all duration-300 hover:bg-blue-500 cursor-pointer p-1 flex items-center rounded-full">
                  <InstagramIcon fontSize="inherit"/>
                </a>
                <a href="https://www.linkedin.com/company/ezshifa/" target="_blank" className="mr-3 text-base bg-white text-black hover:text-white transition-all duration-300 hover:bg-blue-500 cursor-pointer p-1 flex items-center rounded-full">
                  <LinkedInIcon fontSize="inherit"/>
                </a>
                </li>
      <button onClick={() => window.open('https://ezshifa.com/Findus/' , '_blank')} className="ml-7 text-base bg-white text-black hover:text-white transition-all duration-300 hover:bg-blue-500 cursor-pointer px-2 py-1 flex items-center rounded-lg font-semibold">
        Find Us
      </button>
    </div>
  </header>
  </div>
  )
}

export default Webheader
