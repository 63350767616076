import React, { useEffect, useState, useRef, useMemo, useContext } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AddAlertOutlinedIcon from "@mui/icons-material/AddAlertOutlined";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import ReplayIcon from '@mui/icons-material/Replay';
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { useLocation, useNavigate, useParams } from "react-router";
import Geocode from "react-geocode";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import axios from "axios";
import ClearIcon from '@mui/icons-material/Clear';
import { sidebarcontext } from "../screens/dashboard";
import { Link } from "react-router-dom";
import { notifications } from "../api";
import ambu from "../assets/ambu.png"
import ambu2 from "../assets/ambu2.png"
import OutsideClickHandler from "react-outside-click-handler";
import { memo } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

function Header({
  iconName,
  name,
  latitude,
  longitude,
  searchCity,
  searchCountry,
}) {
  const classes = useStyles();
  const Swal = require("sweetalert2");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [cities, setCity] = useState("");
  const [country, setCountry] = useState("");
  const token = localStorage.getItem("accessToken");
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [mydrop , setMydrop] = useState(false)
  const loaded = useRef(false);
  const [respdata , setResp] = useState(null)
  const {sidebar , setSidebar} = useContext(sidebarcontext)
  const [cngloc , setCngloc] = useState({city : "" , county: "" })
  const Updatedloc = JSON.parse(sessionStorage.getItem('location'))
  const [notification , setNotification] = useState([])
  const [notishow , setNotishow] = useState(false)
const user = JSON.parse(localStorage.getItem('user')) 
var patientId;
        if (user === null) {
          patientId = 0;
        } else {
          patientId = user.id;
        }

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyDRojKVNnXAK6Gsgo6xF_ptX9etpa3LVWs&libraries=places",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  } 
  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
      loc({lat: position.coords.latitude, long : position.coords.longitude}) ;
    });
  };

  const loc = async ({lat , long}) => {
    const res = await axios.get(`https://njs-uat.ezshifa.com/api/geo/geocode?lat=${lat && lat}&long=${long && long}`)
    setCity(Updatedloc ? Updatedloc.city : res.data.city);
    setCountry(Updatedloc ? Updatedloc.county : res.data.country ? res.data.country : "Pakistan")
  }
  const navigate = useNavigate();
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to log out? Please select 'Yes' or 'No'.",
      icon: "info",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("_grecaptcha");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        localStorage.removeItem("refresh");
        localStorage.removeItem("freeappts");
        localStorage.removeItem("remainingappt");
        localStorage.removeItem("freepopup");
        navigate("/login");
      }
    });
  };
  const {pathname} = useLocation();
  const olduser = localStorage.getItem('log')
  const handleLogin = () => {
    if(!olduser && !pathname.includes("register")){
      navigate("/register");
    }else if(pathname.includes("login"))
    {
      navigate("/register");
    }
    else
    {
      navigate("/login");
    }  
  };
  const handleAmbulance = () => {
    navigate("/ambulance");
  };
  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, types: ["(cities)"] }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  
  const nofifications = () => {
    const res = notifications({patientId}).then(
      res => setNotification(res.data.data)
    )
  } 

  const handleRoutes = (route) => {
    if (token === null) {
      Swal.fire({
        title: "User not login, Please login",
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Go to login",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/login?redirectTo=${route}`);
        }
      });
    } else {
      navigate(route);
    }
  };
  
  useEffect(() => {
    getLocation();
    console.clear();
    loc();
  }, []);

  useMemo(() => {
    nofifications();
  } , [notishow])
  return (
    // <div className="flex items-center justify-center h-20">
    // <div className={`flex items-center justify-center h-20 absolute lg:top-8 top-28 ${pathname.includes("prescriptionpdf") && "hidden"} z-50 ${sidebar ? "lg:left-[19%]" : "lg:left-[27%]"} xl:left-[17%] left-[4%] lg:w-[70%] md:w-[85%] w-[90%]`}>
    <div className="flex items-center justify-center relative lg:max-w-[70vw]">
       <div className={`flex items-center justify-center h-20 absolute lg:top-2 top-28 ${pathname.includes("login") || pathname.includes("register") ? "top-8" : null} ${pathname.includes("prescriptionpdf") && "hidden"} z-50 lg:left-[50%] lg:w-[70%] md:w-[85%] w-[90%]`}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button onClick={handleClose} className="absolute right-1 top-0"><ClearIcon/></button>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Change Location
          </Typography>
          <div className="mt-2 w-full">
            <Autocomplete
              id="google-map-demo"
              style={{ width: 300 }}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.description
              }
              filterOptions={(x) => x}
              options={options}
              autoComplete
              fullWidth
              includeInputInList
              filterSelectedOptions
              value={value}
              onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                setCngloc({city : searchCity ? newValue.structured_formatting.main_text : cities
                , county : searchCountry ? newValue.structured_formatting.secondary_text : country});
                // setCity(searchCity ? newValue.structured_formatting.main_text : cities);
                // setCountry(searchCountry ? newValue.structured_formatting.secondary_text : country);
                searchCity(newValue.structured_formatting.main_text);
                searchCountry(newValue.structured_formatting.secondary_text);
                Geocode.fromAddress(
                  newValue.structured_formatting.main_text
                ).then(
                  (response) => {
                    const { lat, lng } = response.results[0].geometry.location;
                    latitude(lat);
                    longitude(lng);
                  },
                  (error) => {
                  }
                );
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (<>
                <TextField
                  {...params}
                  label="Add location"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                />
                <div className="text-center mt-3">
                <button onClick={() => {setCity(cngloc.city); setCountry(cngloc.county); 
                sessionStorage.setItem('location' , JSON.stringify(cngloc)); handleClose();}} className="border border-blue-500 bg-blue-200 px-5 py-2 rounded-md text-slate-600 font-semibold tracking-wide hover:text-slate-200 hover:bg-blue-400 transition-all duration-300">Change</button>
                </div>
                </>
              )}
              renderOption={(option) => {
                const matches =
                  option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match) => [
                    match.offset,
                    match.offset + match?.length,
                  ])
                );

                return (
                  <Grid container alignItems="center" className="w-full">
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{ fontWeight: part.highlight ? 700 : 400 }}
                        >
                          {part.text}
                        </span>
                      ))}
                      <Typography variant="body2" color="textSecondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
            />
          </div>
          {/* <div className="mt-2 w-full">
            <Button variant="outlined">Set Location</Button>
          </div> */}
        </Box>
      </Modal>
      <div className="w-full lp:w-[470px] lg:w-[35vw] flex justify-between items-center bg-white h-12 border-l-[10px] border-purple rounded-lg drop-shadow">
        <div className="flex items-center">
          <div className="ml-3 text-purple">{iconName}</div>
          <div className="ml-2">
            <span className="font-normal tracking-wide whitespace-nowrap">{name}</span>
          </div>
        </div>
        <div className="flex items-center justify-end lg:w-[25vw]">
          <div onClick={handleOpen} className="ml-3 text-purple cursor-pointer">
            <LocationOnOutlinedIcon />
          </div>
          <p className="font-medium mx-4">
            {cities && cities + ","} {country ? country : "Pakistan" }
          </p>
        </div>
      </div>
      <div className="hidden lp:flex lg:flex relative items-center">
      <OutsideClickHandler
      onOutsideClick={() => {
        setMydrop(false)
      }}
    >
      {token && <p onClick={() => {setMydrop(!mydrop)}} className="hidden lp:block lg:block ml-5 font-bold text-[#820182] cursor-pointer transition-all duration-300 hover:text-blue-700 w-[12vw]">Get Quick Access {mydrop ? "⇑" : "⇓"} </p>}

      {token && <div onClick={() => setMydrop(false)} className={`${!mydrop && "scale-0"} absolute top-12 z-10 w-[50%] transition-all duration-300 border border-slate-200 rounded-md`}>
        <div
          onClick={handleAmbulance}
          className="cursor-pointer flex items-center justify-center bg-white w-full h-10 drop-shadow border border-slate-200 border-x-0 border-t-0 hover:bg-slate-300 transition-all duration-300"
        >
          <img src={ambu2} className="w-6" alt="" />
        </div>
        <div onClick={(e) => {e.stopPropagation(); if(!token){
          handleRoutes("/main")
        }else{setNotishow(!notishow)}}} className="flex items-center relative cursor-pointer justify-center bg-white w-full h-10 drop-shadow border border-slate-200 border-x-0 border-t-0 hover:bg-slate-300 transition-all duration-300">
          {notification ? <NotificationImportantIcon className={`text-red-500 animate-pulse`}/> : <NotificationsActiveOutlinedIcon />}
          <div className={`absolute left-[100%] md:w-[18vw] xl:w-[25vw] top-0 overflow-y-scroll h-[25vh] transition-all duration-300 bg-white rounded-md border border-slate-300 p-3 ${!notishow && "translate-x-[100vw]"}`}>
          {notification?.length > 0 ? notification.map(n => <>
            <p className={`font-semibold py-3 hover:bg-slate-200 px-2 rounded-md`}>{n.notification}</p>
            <hr className="text-slate-300" />
            </>
          ) : <p className={`font-semibold py-3`}>No notifications yet!</p>}
          </div>
        </div>
        <div onClick={() => handleRoutes("/docChat")} className="flex cursor-pointer items-center justify-center bg-white w-full h-10 drop-shadow border border-slate-200 border-x-0 border-t-0 hover:bg-slate-300 transition-all duration-300">
          <MessageOutlinedIcon />
        </div>
        <a href="https://wa.me/+923005018328" target="_blank" className="flex items-center justify-center bg-white rounded-b-md w-full h-10 drop-shadow text-green-500 border border-slate-200 border-t-0 border-x-0 hover:bg-slate-300 transition-all duration-300">
          <WhatsAppIcon />
        </a>
        </div>}
    </OutsideClickHandler>
        
        {token ? (
          <>
            <div
              onClick={handleLogout}
              className="cursor-pointer flex items-center justify-center rounded-lg px-6 h-10 drop-shadow ml-4 text-white bg-purple"
            >
              Logout
              <span className="ml-3">
                <LogoutIcon />
              </span>
            </div>
          </>
        ) : (
          <>
            <div
              onClick={handleLogin}
              className="cursor-pointer flex items-center justify-center rounded-lg px-8 h-10 drop-shadow ml-4 text-white bg-purple"
            >
              {!olduser && !pathname.includes("register") ? "Register" : pathname.includes("register") ? "Login" : pathname.includes("login") ? "Register" : "Login"}
              <span className="ml-3">
                <LockOpenIcon />
              </span>
            </div>
          </>
        )}
        <div
              onClick={() => window.location.reload()}
              className="cursor-pointer flex items-center justify-center rounded-lg px-2 hover:bg-purple hover:text-white h-10 group drop-shadow ml-3 text-purple border border-purple"
            >
              <ReplayIcon className="group-hover:active:animate-ping"/>
            </div>
        
      </div>
    </div>
    </div>
  );
}
export default memo(Header)

