import React, { Suspense, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import Header from "../components/header";
// import DocterCard from "../components/docterCard";
import StarIcon from "@mui/icons-material/Star";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Facility from "../assets/facility.png";
// import Carousel from "react-elastic-carousel";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { consts } from 'react-elastic-carousel';
import Item from "./Item";
import {
  getSpecialities,
  recommendedDoctors,
  getFacilities,
  searchQuery,
  getHintData,
  checkfreeappt,
} from "../api";
import Geocode from "react-geocode";
import Select from "react-select";
import "./style.css";
import { useNavigate, useLocation } from "react-router";
import toast, { Toaster } from "react-hot-toast";
import { useMediaQuery } from "../util/useMediaQuery";
import { load } from "./constant";
import { useMemo } from "react";
import Swal from "sweetalert2";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 3 },
  { width: 768, itemsToShow: 5 },
  { width: 850, itemsToShow: 4 },
  { width: 1200, itemsToShow: 5 },
];
function myArrow({ type, onClick, isEdge }) {
  const pointer = type === consts.PREV ? <KeyboardDoubleArrowLeftIcon className="hover:bg-blue-200 rounded-full" /> : <KeyboardDoubleArrowRightIcon className="hover:bg-blue-200 rounded-full" />
  return (
    <button onClick={onClick} disabled={isEdge}>
      {pointer}
    </button>
  )
}

function MainPage() {
  var token = null;
  const DocterCard = React.lazy(() => import('../components/docterCard'));
  const Carousel = React.lazy(() => import('react-elastic-carousel'));

  var refreshh = localStorage.getItem("refresh");
  const { state } = useLocation();
  const reload = () => {
    if (refreshh === null) {
      localStorage.setItem("refresh", "1");
      window.location.reload();
    }
  };

  const isMobilew = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");
  const [specLoading, setSpecLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [queryDoc, setQueryDoc] = useState([]);
  const [docCity, setDocCity] = useState([]);
  const [docNear, setDocNear] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [specialities, setSpecialities] = useState([]);
  const [doc, setDoc] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [cities, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [hintData, setHintData] = useState([]);
  const [sourceId, setSourceId] = useState("");
  const [selectedSpec, setSelectedSpec] = useState("Family/ General / Medical Physician");
  const [pricefilter, setPricefilter] = useState("");
  const [limit, setLimit] = useState(6);

  const [freeappt, setFreeappt] = useState({
    total: "", remaining: "", show: true
  });

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  var userId = "";
  if (user === null) {
    userId = 0;
  } else {
    userId = user.id;
  }
  const directSearch = async (id, type) => {
    if (type === "Doctor") {
      navigate("/bookAppointment/" + id);
    }
  };
  // --------------------------------------------Remove duplicates----------------------------
  function removeSimilarObjects(arr1, arr2) {
    const titlesArr1 = arr1?.map(obj => obj.title);
    return arr2?.filter(obj => !titlesArr1.includes(obj.title));
  }
  function removeSimilarObjects(arr1, arr2) {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
      // Handle the case where arr1 or arr2 is not an array
      return [];
    }

    const titlesArr1 = arr1.map(obj => obj.title);
    return arr2.filter(obj => !titlesArr1.includes(obj.title));
  }
  // ------------------------------------------------------

  const searchDoc = async (e) => {
    if (e.type === "Doctor") {
      navigate("/bookAppointment/" + e.value);
    }
    else if(e.type === "All"){
      getDoctors();
      setSelectedSpec("");
      setLimit(6);
      window.scrollTo({ top: 430, behavior: "smooth" })
    }
     else {
      getSpeciDoctors({ title: e.label });
      setSelectedSpec(e.label)
      setLimit(6);
      window.scrollTo({ top: 430, behavior: "smooth" });
      if (localStorage.getItem('recentspec')) {
        localStorage.setItem('recentspec', JSON.stringify(e.label).concat(localStorage.getItem('recentspec')))
      }
      else {
        localStorage.setItem('recentspec', JSON.stringify(e.label));
      }
      // const query = await searchQuery({
      //   search,
      //   lat,
      //   long,
      //   type,
      //   cities,
      //   country,
      // }).then((result) => {
      //   list.push(result.data.doctorsOnline);
      //   city.push(result.data.doctorsWithinCity);
      //   near.push(result.data.doctorsNearby);
      //   setShowSearch(true);
      // });
      // setQueryDoc(...list);
      // setDocCity(...city);
      // setDocNear(...near);
    }
  };

  const get = async () => {
    const response = await getSpecialities()
      .then((res) => {
        setSpecialities(res.data);
        setSpecLoading(false);
      })
      .catch((err) => {
        setSpecLoading(false);
      });
  };
  const getDoctors = async () => {
    const response = await recommendedDoctors({ userId, limit }).then((res) => {
      if (res.data === "Invalid Token") {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        navigate("/login");
        Swal.fire({
          title: 'Your session has timed out. Please log in again.',
          text: "Please login again!",
          icon: 'error',
          // showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false)
          }
        })
      } else {
        setDoc(res.data);
        setLoading(false);
      }
    });
  };
  const getSpeciDoctors = async ({ title }) => {
    const response = await searchQuery({ search: title, type: "Speciality" }).then((res) => {
      if (res.data === "Invalid Token") {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        navigate("/login");
        Swal.fire({
          title: 'Your session has timed out. Please log in again.',
          text: "Please login again!",
          icon: 'error',
          // showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false)
          }
        })
      }
      else {
        setDoc(res.data);
        setLoading(false);
      }
    });
  };
  const getF = async () => {
    const response = await getFacilities();
    setFacilities(response.data);
  };
  const getSearchHint = async () => {
    const response = await getHintData();
    const All = { sourceId: 'All', sourceType: 'All', sourceName: 'All' };
    setHintData([All, ...response.data]);

  };

  useEffect(() => {
    token = localStorage.getItem("accessToken")
    if (token) {
      freeaptcheck();
      setLoading(true);
      setSpecLoading(true);
      get();
      getF();
      getSearchHint();
      getSpeciDoctors({ title: "Family/ General / Medical Physician" });
      window.dispatchEvent(new Event("storage"));
      if (state != null) {
        reload();
      }
      if (refreshh === "1") {
        toast.success("Login Successfull!");
        localStorage.setItem("refresh", "2");
      }
    }
    // console.clear();
  }, []);
  // ----------------------------------Recent Spec------------------------------------------------
  let recentspec = [];
  let disresspec = []
  recentspec.unshift('Family/ General / Medical Physician')
  if (recentspec.length > 0) {
    localStorage.getItem('recentspec')?.split('\"').filter(n => n != '')?.map(
      n => {
        recentspec.unshift(n)
      }
    )
    const uniqueArray = recentspec?.filter((value, index) => {
      const _value = JSON.stringify(value);
      return index === recentspec?.findIndex(obj => {
        return JSON.stringify(obj) === _value;
      });
    })
    uniqueArray?.map(n => {
      if (Array.isArray(specialities) && specialities.length > 0) {
        const filteredSpecialities = specialities.filter((item) => n === item.title);
        disresspec.unshift(...filteredSpecialities);
      }
      // if(specialities?.length > 0){
      // specialities?.filter((item) => n === item.title)?.map(n => {
      //   disresspec.unshift(n)
      // })}
    })
  }

  // -----------------------------------------------------------------

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  // ----------------------------------------------
  const freeaptcheck = async () => {
    const response = await checkfreeappt({
      pid: userId
    });
    if (response.data === "Invalid Token") {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      navigate("/login");
      Swal.fire({
        title: 'Your session has timed out. Please log in again.',
        text: "Please login again!",
        icon: 'error',
        // showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false)
        }
      })
    }
    else {
      setFreeappt({ ...freeappt, total: response.data.total_appt, remaining: response.data.rem_appt })
      localStorage.setItem('freeappts', response.data.rem_appt)
    }
  }
  const closefreepopup = () => {
    localStorage.setItem('freepopup', true)
    setFreeappt({ ...freeappt, show: false })
    window.dispatchEvent(new Event("storage"))
  }
  let freepopupstatus = JSON.parse(localStorage.getItem('freepopup'))

  let screenwidth = window.innerWidth
  return (
    <div className="main flex-1 items-center mx-3 lp:mx-0 lg:mx-0 min-h-screen rounded-lg lg:pt-14 pt-40 lp:mt-0 lg:mt-0">
      {/* ---------------------------------------------------------------------------------------Popup------------- */}
      {freeappt.total &&
        freeappt.remaining > 0 &&
        freeappt.show &&
        !freepopupstatus &&
        <div onClick={closefreepopup} className='bg-black fixed inset-0 flex items-center justify-center bg-opacity-50 z-50'>
          <div onClick={closefreepopup} className="p-4 bg-gray-900 bg-opacity-50 w-full flex justify-center items-center">
            <div onClick={e => e.stopPropagation()} className="bg-white rounded-lg shadow-lg lg:w-[40%] max-lg:w-[90%]">

              <div className="px-6 py-4 text-center relative">

                <div onClick={closefreepopup} className="absolute top-0 right-0 p-4 hover:bg-slate-200 rounded-full cursor-pointer">
                  <button className="bg-gray-100 hover:bg-gray-200 rounded-full p-2 focus:outline-none">
                    <svg className="w-6 h-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>

                <img src="https://img.freepik.com/premium-vector/young-male-doctor-was-carrying-writing-instrument_490615-418.jpg" alt="Celebration" className="w-32 h-32 rounded-full object-cover mt-5 mx-auto mb-4" />

                <h1 className="text-2xl font-bold text-green-500 mb-2">Congratulations {user.username}!</h1>

                {/* <p className="text-gray-700 mb-4">
      You have {freeappt.total} appointments free.
    </p> */}
                <div className="text-gray-700 mb-4">
                  <span className="font-bold text-gray-500">Total free appointments: {freeappt.remaining + 1}</span>
                  <br />
                  <span className="text-green-500 font-bold text-xl">Remaining Appointments: {freeappt.remaining}</span>
                </div>

                <button onClick={closefreepopup} className="bg-green-500 hover:bg-green-600 text-white font-bold text-lg tracking-wide py-2 px-4 rounded">
                  Awesome!
                </button>
              </div>
            </div>
          </div>
        </div>}
      {/* --------------------------------------------------------------------------------------------------- */}

      <Toaster position="top-right" />
      <div className="md:p-4 md:bg-white md:mr-[2%]">
        <div className="bg-[#F5F5F5] py-2 px-2 md:px-6 rounded-lg">
          <form
            onSubmit={(e) => {
              searchDoc(e);
            }}
          >
            <div className="flex items-center p-1 md:my-2 rounded-sm bg-white text-grayish cursor-pointer z-50">
              <div className="w-full options">
                <Select
                  options={Array.isArray(hintData) && hintData?.map((opt) => ({
                    label: opt.sourceName,
                    value: opt.sourceId,
                    type: opt.sourceType,
                  }))}
                  onChange={(opt) => {
                    setSourceId(opt.value);
                    setSearch(opt.label);
                    setSearchType(opt.type);
                    directSearch(opt.value, opt.type);
                    searchDoc(opt)
                  }}
                  placeholder="Search Doctor , Specialities..."
                  className="text-[#000]"
                />
              </div>
            </div>
          </form>
        </div>
        <>
          {/* <div className="flex items-center justify-center w-full bg-[#F5F5F5] max-md:bg-transparent mt-2 pt-1 pb-2 max-md:mx-0 rounded-lg">
            <div className="w-full md:w-full lg:px-5">
              <div className="pb-2 pt-2 md:pl-4">
                <p className="font-medium text-lg text-slate-700">Search by Specialization</p>
                <hr className="w-[12.5rem] mt-1 text-slate-300" />
              </div>
              {specLoading == false ? (
                <>
                  <Suspense fallback={load}>
                    <Carousel
                      breakPoints={breakPoints}
                      itemsToScroll={1}
                      itemsToShow={4}
                      pagination={screenwidth < 700 ? false : true}
                      // showArrows={screenwidth < 700 ? true : false}
                      renderArrow={myArrow}
                    >
                      {Array.isArray(disresspec) && disresspec?.map((item) => (
                        !item.image ? null :
                          <Item key={item.title}>
                            <div
                              onClick={(event) => {
                                getSpeciDoctors({ title: item.title });
                                setSelectedSpec(item.title)
                                setLimit(4);
                                window.scrollTo({ top: 430, behavior: "smooth" });
                                if (localStorage.getItem('recentspec')) {
                                  localStorage.setItem('recentspec', JSON.stringify(item.title).concat(localStorage.getItem('recentspec')))
                                }
                                else {
                                  localStorage.setItem('recentspec', JSON.stringify(item.title));
                                }
                              }
                              }
                              className={
                                selectedSpec === item.title
                                  ? "flex flex-col items-center justify-center mx-2 w-full md:w-[180px] min-h-[130px] p-4 my-1 rounded-lg border border-purple bg-white drop-shadow text-white cursor-pointer z-0"
                                  : "flex flex-col items-center hover:border hover:border-fuchsia-500 justify-center mx-2 w-full md:w-[180px] min-h-[130px] p-4 my-1 rounded-lg bg-white drop-shadow text-grayish cursor-pointer z-0"
                              }
                            >
                              <img
                                src={item.image}
                                className="w-16 h-16"
                                alt="SVG"
                              />
                              <p className="text-black overflow-hidden text-xs mt-2 font-medium text-center">
                                {item.title}
                              </p>
                            </div>
                          </Item>
                      ))}

                      <Item key={"item.title"}>
                        <div
                          onClick={(event) => {
                            getDoctors();
                            setSelectedSpec("");
                            setLimit(4);
                            window.scrollTo({ top: 430, behavior: "smooth" })
                          }
                          }
                          className={
                            selectedSpec === ""
                              ? "flex flex-col items-center justify-center mx-2 w-full md:w-[180px] min-h-[130px] p-4 my-1 rounded-lg border border-purple bg-white drop-shadow text-white cursor-pointer z-0"
                              : "flex flex-col items-center hover:border hover:border-fuchsia-500 justify-center mx-2 w-full md:w-[180px] min-h-[130px] p-4 my-1 rounded-lg bg-white drop-shadow text-grayish cursor-pointer z-0"
                          }
                        >
                          <img
                            src="/doc.png"
                            className="w-16 h-16"
                            alt="SVG"
                          />
                          <p className="text-black overflow-hidden text-xs whitespace-nowrap text-ellipsis mt-2 font-medium">
                            All
                          </p>
                        </div>
                      </Item>
                      {removeSimilarObjects(disresspec, specialities)?.map((item) => (
                        item.title.includes("CardioMedical") || !item.image ? null :
                          <Item key={item.title}>
                            <div
                              onClick={(event) => {
                                getSpeciDoctors({ title: item.title });
                                setSelectedSpec(item.title)
                                setLimit(4);
                                window.scrollTo({ top: 430, behavior: "smooth" });
                                if (localStorage.getItem('recentspec')) {
                                  localStorage.setItem('recentspec', JSON.stringify(item.title).concat(localStorage.getItem('recentspec')))
                                }
                                else {
                                  localStorage.setItem('recentspec', JSON.stringify(item.title));
                                }
                              }
                              }
                              className={
                                selectedSpec === item.title
                                  ? "flex flex-col items-center justify-center mx-2 w-full md:w-[180px] min-h-[130px] p-4 my-1 rounded-lg border border-purple bg-white drop-shadow text-white cursor-pointer z-0"
                                  : "flex flex-col items-center hover:border hover:border-fuchsia-500 justify-center mx-2 w-full md:w-[180px] min-h-[130px] p-4 my-1 rounded-lg bg-white drop-shadow text-grayish cursor-pointer z-0"
                              }
                            >
                              <img
                                src={item.image}
                                className="w-16 h-16"
                                alt="SVG"
                              />
                              <p className="text-black overflow-hidden text-xs mt-2 font-medium text-center">
                                {item.title}
                              </p>
                            </div>
                          </Item>
                      ))}
                    </Carousel>
                  </Suspense>
                </>
              ) : (
                <>
                  {load}
                </>
              )}
            </div>
          </div> */}
          <div className="flex justify-between max-md:flex-col max-md: space-y-3 items-center pb-1 pt-1">
            <div className="">
              <p className="mt-4 font-medium text-lg text-slate-700">Your Doctors Team</p>
              <hr className={`w-[10rem] mt-1 text-slate-300 ${limit > 6 && "hidden"}`} />
            </div>
            <div className="flex items-center border border-slate-400 rounded-md overflow-hidden">
              <input type="text" placeholder="Filter" onBlur={e => setPricefilter(e.target.value)} className="py-1 px-2" />
              <button><SearchIcon /></button>
            </div>
          </div>
          {loading == false ? (
            <>
            {/* ${doc.length >= 3 ? "lg:h-[800px]" : "lg:h-[450px]"} rounded-l-md ${limit > 4 && "overflow-y-scroll border border-slate-300"} */}
              <div className={`flex justify-center items-center flex-wrap py-2 `}>
              {Array.isArray(doc) && doc.length > 0 ? 
    doc
        .sort((a, b) => {
            // Sorting based on onlineStatus
            if (a.onlineStatus === "Online" && b.onlineStatus !== "Online") return -1; // If a is Online, push it up
            if (a.onlineStatus !== "Online" && b.onlineStatus === "Online") return 1; // If b is Online, push it up
            // Sorting based on isKioskDr
            if (a.isKioskDr && !b.isKioskDr) return -1; // If a is kiosk, push it up
            if (!a.isKioskDr && b.isKioskDr) return 1; // If b is kiosk, push it up
            return 0; // Otherwise, maintain order
        })
        .slice(0, limit)
        .filter(n => {
            if (pricefilter === "") {
                return n;
            } else if (String(Object.values(n)).toLowerCase().includes(pricefilter.toLowerCase())) {
                return n;
            }
        })
        .map((item) => (
                    <>
                      <Suspense fallback={load} key={item.id}>
                        <DocterCard
                          docId={item.id}
                          callStatus={item.callStatus}
                          specialities={item.doctorSpecialities}
                          doctorsRating={item.doctorsRatings}
                          firstName={item.firstName}
                          middleName={item.middleName}
                          lastName={item.lastName}
                          experience={item.experience}
                          profilePic={item.profilePicture}
                          isKioskDr={item.isKioskDr}
                          doctor_slot={item.doctor_slot}
                          isFavorite={item.isFavorited}
                          onlineStatus={item.onlineStatus}
                          availableDays={item.availableDays}
                          fee={item.Fee}
                          gender={item.gender}
                          callbtn={true}
                          remainingappt={freeappt.remaining - 1}
                        />
                      </Suspense>
                    </>
                  )) :
                  <div>
                    <p className="text-3xl text-slate-500">No Record found!</p>
                  </div>
                }
              </div>
              <div className="text-center my-7">
                <button onClick={() => { setLimit(limit + 3) }} className={`border-2 ${limit >= doc.length ? "bg-slate-200 pointer-events-none border-slate-300 text-slate-400" : "border-[#982a8f] text-[#982a8f] mt-8"} w-[20%] max-md:w-[80%] py-2 rounded-md font-semibold hover:bg-[#982a8f] hover:text-slate-200 transition-all duration-300`}>See More Doctors</button>
              </div>
            </>
          ) : (
            <>
              {load}
            </>
          )}
          {/* <div className="py-9">
              <p className="mt-4 font-medium text-lg text-slate-700">Hospitals Near by you</p>
              <hr className="w-[11.5rem] mt-1 text-slate-300" />
            </div> */}
          {/* <div className="flex items-center justify-center ">
              <div className="w-full h-72 mb-10 z-0">
                <Suspense fallback={load}>
                  <Carousel
                    itemsToScroll={1}
                    itemsToShow={isMobilew ? 1 : isTablet ? 2 : 3}
                    pagination={screenwidth < 700 ? false : true}
                    renderArrow={myArrow}
                  >
                    {Array.isArray(facilities) && facilities?.slice(0,25).map((item) => (
                      <Item key={item.id}>
                        <div className="flex flex-col bg-gray rounded-lg mt-3 drop-shadow-lg w-[300px] md:w-80 mx-auto md:ml-6 p-4 z-0 filter-none">
                          <div className="flex justify-between">
                            <div className="flex text-black">
                              <span>4.3</span>
                              <div className="ml-1 text-[#F1965B]">
                                <StarIcon />
                              </div>
                            </div>
                            <div className="text-xs font-medium -mr-2 flex items-center bg-[#cf0000] p-1 rounded-lg text-white">
                              <CheckCircleIcon />
                              24hr Emergency Available
                            </div>
                          </div>
                          <div className="flex items-center mt-4">
                            <div className="bg-white rounded-lg p-4 drop-shadow-lg">
                              <img src={Facility} className="w-20 h-20" />
                            </div>
                            <div className="ml-4">
                              <p className="font-medium text-black">
                                {item.name}
                              </p>
                              <p className="text-black">
                                {item.city.name} {item.state.name}{" "}
                                {item.country.name}
                              </p>
                            </div>
                          </div>
                          <button
                            onClick={(event) => {getSpeciDoctors({title: item.name});  setSelectedSpec(""); setLimit(4); window.scrollTo({top : 430, behavior:"smooth"})}}
                            className="text-sm font-medium py-3 w-full mt-2 -mb-2 border rounded-md bg-white text-purple hover:bg-fuchsia-200"
                          >
                            View Details
                          </button>
                        </div>
                      </Item>
                    ))}
                  </Carousel>
                </Suspense>
              </div>
            </div> */}
        </>
      </div>
    </div>
  );
}
export default MainPage;
