import React, { createContext, useContext, useEffect, useState } from "react";
import Logo from "../assets/logo.png";
import DocApp from "../assets/docApp.png";
import Playstore from "../assets/googlePlay.png";
import HomeIcon from "@mui/icons-material/Home";
import SidebarOption from "../components/sidebarOption";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import CloseIcon from "@mui/icons-material/Close";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import { Link, Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddAlertOutlinedIcon from "@mui/icons-material/AddAlertOutlined";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LogoutIcon from "@mui/icons-material/Logout";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatIcon from "@mui/icons-material/Chat";
import VerifiedIcon from "@mui/icons-material/Verified";
import SecurityIcon from "@mui/icons-material/Security";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SegmentIcon from '@mui/icons-material/Segment';
import SortIcon from '@mui/icons-material/Sort';
import ambu2 from "../assets/ambu2.png"

import { useMemo } from "react";
import { checkfreeappt, getDoctor, getPatient, getPrescription, notifications } from "../api";

export const sidebarcontext = createContext({})

export default function Dashboard() {
  const [isActive, setActive] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [popup, setPopup] = useState(false);
  const [placeholder, setPlaceholder] = useState({open: "" , close: ""})

  const [SecondaryMenu, setSecondaryMenu] = useState(false);
  const user = JSON.parse(localStorage.getItem('user')) 
  const {pathname} = useLocation()
  const [freeappt, setFreeappt] = useState({
    total:"" , remaining: localStorage.getItem('freeappts') , show: true
  });
  const [presc , setPresc] = useState(null)
  const [prescopen, setPrescopen] = useState(false);
  const [prescription, setPrescription] = useState([]);
  const [prescriptionDiagnoses, setPrescriptionDiagnoses] = useState([]);
  const [prescriptionLabtest, setPrescriptionLabtest] = useState([]);
  const [prescriptionMed, setPrescriptionMed] = useState([]);
  const [prescriptionSymp, setPrescriptionSymp] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [patient, setPatient] = useState([]);

  var patientId;
  if (user === null) {
    patientId = 0;
  } else {
    patientId = user.id;
  }
  const Swal = require("sweetalert2");
  const token = localStorage.getItem("accessToken");
  const handleDropdown = () => {
    setDropdown(!dropdown);
  };
  const navigate = useNavigate();
  const toggleMenu = () => {
    setActive(!isActive);
  };
  const toggleSecondaryMenu = () => {
    setSecondaryMenu(!SecondaryMenu);
  };
  const handleMainPage = () => {
    navigate("/main");
    setActive(false);
    setSecondaryMenu(false);
    window.location.reload();
  };
  const hanldeLogoRoute = () => {
    navigate("/main");
    setActive(false);
    setSecondaryMenu(false);
  };
  const handleRoutes = (route) => {
    if (token === null) {
      Swal.fire({
        title: "User not login, Please login",
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Go to login",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/login?redirectTo=${route}`);
        }
      });
    } else {
      navigate(route);
    }
    setActive(false);
    setSecondaryMenu(false);
  };
  const handleAmbulance = () => {
    navigate("/ambulance");
    setSecondaryMenu(false);
  };
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to log out? Please select 'Yes' or 'No'.",
      icon: "info",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: "Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        localStorage.removeItem("freeappts");
        localStorage.removeItem("remainingappt");
        navigate("/login");
      }
    });
    setSecondaryMenu(false);
  };
  const handleLogin = () => {
    navigate("/login");
  };
  const {sidebar , setSidebar} = useContext(sidebarcontext)
  
  const [notification , setNotification] = useState([])
  const [notishow , setNotishow] = useState(false)

  const nofifications = () => {
    const res = notifications({patientId}).then(
      res => setNotification(res.data.data)
    )
  } 
  const freeaptcheck = async () => {
    const response = await checkfreeappt({
      pid: user.id
    });
    setFreeappt({...freeappt, total:response.data.total_appt , remaining: response.data.rem_appt})
  };
  const closefreepopup = () => {
  setPopup(false)
  }
// -------------------------------Presc----------------------

const getDoctorData = async () => {
  const response = getDoctor({ docId: presc?.doctorid }).then((result) => {
    setDoctor(result.data);
    setSpecialities(result.data.doctorSpecialities);
    getPatientsData();
  });
};
const getPatientsData = async () => {
  const response = getPatient({ patientId: presc?.patientid }).then((result) => {
    setPatient(result.data);
  });
};

const getPrescriptions = async () => {
  const prescriptions = [];
  const diagnoses = [];
  const labTest = [];
  const medicines = [];
  const symptoms = [];
  const response = await getPrescription({ appointmentID: presc?.apptid }).then((result) => {
    prescriptions.push(result.data);
    diagnoses.push(result.data.prescriptionDiagnoses);
    labTest.push(result.data.prescriptionLabTests);
    medicines.push(result.data.prescriptionMedicines);
    symptoms.push(result.data.prescriptionSymptoms);
  });
  setPrescription(...prescriptions);
  setPrescriptionDiagnoses(...diagnoses);
  setPrescriptionLabtest(...labTest);
  setPrescriptionMed(...medicines);
  setPrescriptionSymp(...symptoms);
};

const closepresc = () => {
  localStorage.removeItem('presc')
  sessionStorage.removeItem('openpresc')
  setPresc(null)
  setPrescopen(false)
}

const PrescFuncs = () => {
  
  getDoctorData();
  getPatientsData();
  getPrescriptions();
}

useEffect(() => {
  // PrescFuncs();
  window.addEventListener('storage', () => {
    setPrescopen(true);
  setPresc(JSON.parse(localStorage.getItem('presc'))) 
});
let interval;
let openpres = sessionStorage.getItem('openpresc')

if(prescopen, openpres){
interval = setInterval(() => {
  PrescFuncs();
}, 5000);
return () => clearInterval(interval)
}else {
    clearInterval(interval)
  }
  // let intervalId;
  // if (presc !== null) {
  //   intervalId = setInterval(() => {
  //     PrescFuncs();
  //   }, 5000);
  // } else {
  //   clearInterval(intervalId);
  // }

  return () => {
    if (interval) {
      clearInterval(interval);
    }
  };
}, [prescopen]);

// ----------------------------------
  useMemo(() => {
    setFreeappt({...freeappt, remaining: localStorage.getItem('freeappts')})
    window.addEventListener('storage', () => {
      setFreeappt({...freeappt, remaining: localStorage.getItem('freeappts')})
   })
    // {if(token){freeaptcheck()}}
    // nofifications();
  } , [])

  return (
    <div className="overflow-hidden">
      <div className="w-full min-h-screen pb-8 bg-gray flex pt-6">
      {freeappt.total && 
      popup &&
       <div onClick={closefreepopup} className='bg-black fixed inset-0 flex items-center justify-center bg-opacity-50 z-50'>
<div onClick={closefreepopup} className="p-4 bg-gray-900 bg-opacity-50 w-full flex justify-center items-center">
<div onClick={e => e.stopPropagation()} className="bg-white rounded-lg shadow-lg lg:w-[40%] max-lg:w-[90%]">

  <div className="px-6 py-4 text-center relative">
  
    <div onClick={closefreepopup} className="absolute top-0 right-0 p-4 hover:bg-slate-200 rounded-full cursor-pointer">
      <button className="bg-gray-100 hover:bg-gray-200 rounded-full p-2 focus:outline-none">
        <svg className="w-6 h-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>  
    </div>
    
    <img src="https://img.freepik.com/premium-vector/young-male-doctor-was-carrying-writing-instrument_490615-418.jpg" alt="Celebration" className="w-32 h-32 rounded-full object-cover mt-5 mx-auto mb-4"/>
    
    <h1 className="text-2xl font-bold text-green-500 mb-2">Welcome Back, {user.username}!</h1>
    
    {/* <p className="text-gray-700 mb-4">

      { freeappt.remaining > 0 ? `You have ${freeappt.total} appointments free.` : "You don't have any free appointments." }
    </p> */}
      {freeappt.remaining > 0 && <div className="text-gray-700 mb-4">
        <span className="font-bold text-gray-500">Total free appointments: {freeappt.remaining + 1}</span>
        <br/>
        <span className="text-green-500 font-bold text-xl">Remaining Appointments: {freeappt.remaining}</span>  
      </div>}

    <button onClick={closefreepopup} className="bg-green-500 hover:bg-green-600 text-white font-bold text-lg tracking-wide py-2 px-4 rounded">
      { freeappt.remaining > 0 ? "Awesome!" : "Ok" }  
    </button>
  </div>
</div>
</div>
</div>}

{/* ----------------------------------------------------------Prescription--------------------------------------------------------- */}
  {prescopen && presc && <div onClick={() => closepresc()} className='bg-black fixed inset-0 flex items-center justify-center bg-opacity-50 z-50'>
  <div onClick={e => e.stopPropagation()} className={`bg-white border overflow-y-scroll h-[85vh] max-md:px-3 border-slate-400 rounded-md md:w-[80%] w-full md:left-[8%] transition-all duration-300 `}>
          {/* <p className="m-5 animate-pulse font-semibold text-sky-700">Hold on! Doctor is writing a prescription...</p> */}
          <div className="border-t border-grayish">
          <p onClick={() => closepresc()} className="text-right sticky top-0 pr-5 pt-2 cursor-pointer hover:text-red-500">X</p>
          <div className="flex flex-col md:flex-row md:items-center md:mx-20 mt-6">
              <div className="md:w-3/4">
                <img src={Logo} className="w-[170px] my-5" />
              </div>
              <div>
                <p className="font-bold text-xs md:text-lg ">
                  Appointment Information
                </p>
                <p className="text-xs md:text-lg">ID: {presc.apptid}</p>
              </div>
            </div>
            <hr className="text-grayish mt-6" />
            <div className="flex justify-between md:justify-start mt-6 md:px-20 md:pb-10">
              <div className="md:w-3/4">
                <p className="font-bold text-xs md:text-lg">
                  Doctor Information
                </p>
                <p className="text-xs md:text-lg">
                  Name: {doctor?.firstName} {doctor?.middleName}{" "}
                  {doctor?.lastName}
                </p>
                <p className="text-xs md:text-lg">PMDC No: {doctor?.pmdcNo}</p>
                <p className="text-xs md:text-lg">
                  {specialities &&
                    specialities.map((item) => <p>{item.title}</p>)}
                </p>
              </div>
              <div>
                <p className="font-bold text-xs md:text-lg">
                  Patient Information
                </p>
                <p className="text-xs md:text-lg">
                  Name: {patient?.firstName} {patient?.middleName}{" "}
                  {patient?.lastName}
                </p>
                <p className="text-xs md:text-lg">Gender: {patient?.gender}</p>
                <p className="text-xs md:text-lg">Phone: {patient?.phone}</p>
              </div>
            </div>
            {patient?.firstName && <hr className="text-grayish mt-6" />}
            {!patient?.firstName && <div className="text-center w-full">
            <div
    className="inline-block fixed top-[50%] left-[50%] h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
    role="status">
    <span
      className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span>
  </div>
  </div>} 
            <div className="flex flex-col md:flex-row md:mx-20">
              <p className="mt-6 w-full md:w-1/2 md:pr-5">
                <span className="font-bold">Diagnose: </span>
                {prescriptionDiagnoses?.length > 0 ? (
                  <>
                    {prescriptionDiagnoses.map((item) => (
                      <>
                        <div>
                          <p>
                            <span className="font-bold">Title:</span>{" "}
                            {item.diseases.title}
                          </p>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <div>
                      <p>No Diagnoses Found</p>
                    </div>
                  </>
                )}
              </p>
              <p className="mt-6 w-full md:w-1/2 md:pl-5">
                <span className="font-bold">Symptoms: </span>
                {prescriptionSymp?.length > 0 ? (
                  <>
                    {prescriptionSymp.map((item) => (
                      <>
                        <div>
                          <p>Title: {item.symptom.name}</p>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <div>
                      <p>No Symptoms Found</p>
                    </div>
                  </>
                )}
              </p>
            </div>
            <div className="flex flex-col md:flex-row md:mx-20">
              <p className="mt-6 w-full md:w-1/2 md:pr-5">
                <span className="font-bold">Lab Tests: </span>
                {prescriptionLabtest?.length > 0 ? (
                  <>
                    {prescriptionLabtest.map((item) => (
                      <>
                        <div>
                          <p>
                            <span className="font-bold">Title:</span>{" "}
                            {item.labTests.name}
                          </p>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <div>
                      <p>No Lab Tests Found</p>
                    </div>
                  </>
                )}
              </p>
              <div className="mt-6 w-full md:w-1/2 md:pl-5">
                <p className="font-bold">Medicines</p>
                <ul className="list-disc text-sm ml-3">
                  {prescriptionMed?.length > 0 ? (
                    <>
                      {prescriptionMed.map((item) => (
                        <>
                          <li className="text-xs md:text-lg">
                            <span className="font-bold text-xs md:text-lg">
                              Name -{" "}
                            </span>
                            {item.medicines.name}{" "}
                            <span className="ml-4 font-bold text-xs md:text-lg">
                              Freq -{" "}
                            </span>
                            {item.frequency}{" "}
                            <span className="ml-4 font-bold text-xs md:text-lg">
                              Duration -{" "}
                            </span>
                            {item.duration}
                          </li>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <li>No Medicines Found</li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="border-t mt-5 border-grayish">
            <p className="my-6 md:mx-20">
              <span className="font-bold">Doctor Suggestion: </span>
              {prescription.note}
            </p>
          </div>
        </div>
        </div>}
{/* ---------------------------------------------------------------------------------------------------------------------------------- */}
        <div class="absolute max-md:top-32 max-sm:top-44 md:mb-10 inset-x-0 top-32 w-full flex items-center justify-between bg-white h-16 shadow-md border-b border-b-purple z-40 lp:hidden lg:hidden">
          <div className="pl-4">
            <div onClick={toggleMenu} className="text-purple cursor-pointer">
              <MenuIcon />
            </div>
          </div>
          <div>
            <img
              onClick={hanldeLogoRoute}
              className="w-40 cursor-pointer"
              src={Logo}
              alt="logo"
            />
          </div>
          <div className="pr-4">
            <div
              onClick={toggleSecondaryMenu}
              className="text-purple cursor-pointer"
            >
              <ExpandMoreIcon />
            </div>
          </div>
        </div>

        {/* Mobile Secondary Menu Starts */}

        <div
          className="absolute top-0 bg-[#fffff1]  w-full z-50 transition-all duration-700 lp:hidden lg:hidden"
          style={
            SecondaryMenu === true
              ? { marginTop: "0px" }
              : { marginTop: "-1500px" }
          }
        >
          <div className="flex items-center justify-between py-5 border-b border-b-purple">
            <div className="pl-4">
              <img
                onClick={hanldeLogoRoute}
                className="w-40 cursor-pointer"
                src={Logo}
                alt="logo"
              />
            </div>
            <div className="pr-4">
              <div
                onClick={toggleSecondaryMenu}
                className="text-purple cursor-pointer"
              >
                <CloseIcon />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            {/* <div className="flex items-center py-4 pl-4 border-b border-b-purple">
              <div className="flex items-center justify-center rounded-lg bg-white w-10 h-10 drop-shadow-lg text-orange-600">
                <AddAlertOutlinedIcon />
              </div>
              <div className="ml-4">Emergency</div>
            </div> */}
            <div
              onClick={handleAmbulance}
              className="flex cursor-pointer items-center py-4 pl-4 border-b border-b-purple"
            >
              <div className="flex items-center justify-center rounded-lg bg-white w-10 h-10 drop-shadow-lg">
              <img src={ambu2} className="w-6" alt="" />
              </div>
              <div className="ml-4">Ambulance</div>
            </div>
            {/* <div className="flex items-center py-4 pl-4 border-b border-b-purple">
              <div className="flex items-center justify-center rounded-lg bg-white w-10 h-10 drop-shadow-lg">
                <NotificationsActiveOutlinedIcon />
              </div>
              <div className="ml-4">Notifications</div>
            </div> */}
            <div onClick={() => {!token ? handleRoutes("/login") : setNotishow(!notishow);
              window.scrollTo({
                top: 300, 
                behavior:"smooth"
              })
            }} className="flex items-center py-4 pl-4 border-b border-b-purple cursor-pointer">
          {notification ? <div className="flex items-center justify-center rounded-lg bg-white w-10 h-10 drop-shadow-lg"><NotificationImportantIcon className={`text-red-500 animate-pulse`}/> </div> 
          :<div className="flex items-center justify-center rounded-lg bg-white w-10 h-10 drop-shadow-lg"> <NotificationsActiveOutlinedIcon /> </div>}
          <div className="ml-4">Notifications</div>
         </div>
            <div onClick={() => handleRoutes("/docChat")} className="flex items-center py-4 pl-4 border-b border-b-purple">
              <div className="flex items-center justify-center rounded-lg bg-white w-10 h-10 drop-shadow-lg">
                <MessageOutlinedIcon />
              </div>
              <div className="ml-4">Chat</div>
            </div>
            <a href="https://wa.me/+923005018328" className="flex items-center py-4 pl-4 border-b border-b-purple">
              <div className="flex items-center justify-center rounded-lg bg-white w-10 h-10 drop-shadow-lg text-green-500">
                <WhatsAppIcon />
              </div>
              <div className="ml-4">WhatsApp</div>
            </a>
            <div className="flex items-center py-4 px-4">
              {token ? (
                <>
                  <div
                    onClick={handleLogout}
                    className="cursor-pointer flex items-center justify-center rounded-lg px-6 h-10 drop-shadow w-full text-white bg-purple"
                  >
                    Logout
                    <span className="ml-3">
                      <LogoutIcon />
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div
                    onClick={handleLogin}
                    className="cursor-pointer flex items-center justify-center rounded-lg px-8 h-10 drop-shadow w-full text-white bg-purple"
                  >
                    Login
                    <span className="ml-3">
                      <LockOpenIcon />
                    </span>
                  </div>
                </>
              )}
            </div>
            <div onClick={(e) => e.stopPropagation()} className= {`bg-white rounded-md border border-slate-300 p-3 ${!notishow ? "w-0 h-0 scale-0" : "overflow-y-scroll h-[45vh]"}`}>
                    {notification?.length > 0 ? notification.map(n => <>
                      <p className={`font-semibold py-3 transition-all duration-300 ${!notishow && "w-0 h-0 scale-0"}`}>{n.notification}</p>
                      <hr className="text-slate-300" />
                    </>
                    ) : <p className={`font-semibold py-3`}>No notifications yet!</p>}
            
            </div>
            <div onClick={() => {setNotishow(false); window.scrollTo({
                  top:-300, 
                  behavior:"smooth"
                })}} className={`pl-4 w-full text-center hover:bg-slate-200 py-2 transition-all duration-300 ${!notishow && "scale-0"}`}>
                <div className="text-purple cursor-pointer">
                  <CloseIcon />
                </div>
              </div>
          </div>
        </div>

        {/* Mobile Secondary Menu Ends */}

        {/* Mobile Main Menu Starts */}

        <div
          className="min-h-screen absolute top-0 bg-[#fffff1]  w-full z-50 transition-all duration-700 lp:hidden lg:hidden"
          style={
            isActive === true ? { marginTop: "0px" } : { marginTop: "-1500px" }
          }
        >
          <div className="flex items-center justify-between py-5 border-b border-b-purple">
            <div className="pl-4">
              <img
                onClick={hanldeLogoRoute}
                className="w-40 cursor-pointer"
                src={Logo}
                alt="logo"
              />
            </div>
            <div className="pr-4">
              <div onClick={toggleMenu} className="text-purple cursor-pointer">
                <CloseIcon />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            
            {token === null ? (
              <></>
            ) : (
              <>
              <div
              onClick={handleMainPage}
              className="cursor-pointer border-b border-b-purple pb-2"
            >
              <SidebarOption iconName={<HomeIcon />} text="Dashboard" />
            </div>
                <div
                  onClick={() => handleRoutes("/myAppointments")}
                  className="cursor-pointer border-b border-b-purple pb-2"
                >
                  <SidebarOption
                    iconName={<CalendarMonthIcon />}
                    text="My Appointment"
                  />
                </div>
                <div
                  onClick={() => handleRoutes("/myVitals")}
                  className="cursor-pointer border-b border-b-purple pb-2"
                >
                  <SidebarOption
                    iconName={<VolunteerActivismIcon />}
                    text="My Vitals"
                  />
                </div>
                <div
                  onClick={() => handleRoutes("/healthRecord")}
                  className="cursor-pointer border-b border-b-purple pb-2"
                >
                  <SidebarOption
                    iconName={<MedicalServicesIcon />}
                    text="Upload Report"
                  />
                </div>
                <div
                  onClick={() => handleRoutes("/favDoc")}
                  className="cursor-pointer border-b border-b-purple pb-2"
                >
                  <SidebarOption
                    route="/favDoc"
                    iconName={<FavoriteIcon />}
                    text="Favorite Doctors"
                  />
                </div>
                <div
                  onClick={() => handleRoutes("/docChat")}
                  className="cursor-pointer border-b border-b-purple pb-2"
                >
                  <SidebarOption
                    route="/doctChat"
                    iconName={<ChatIcon />}
                    text="Chat with Doctor"
                  />
                </div>
                <div
                  onClick={() => handleRoutes("/ambulance")}
                  className="cursor-pointer border-b border-b-purple pb-2"
                >
                  <SidebarOption
                    route="/ambulance"
                    iconName={<img src={ambu2} className="w-6" alt="" />}
                    text="Ambulance"
                  />
                </div>
                <div
                  onClick={() => handleRoutes("/profile")}
                  className="cursor-pointer border-b border-b-purple pb-2"
                >
                  <SidebarOption
                    iconName={<PermIdentityIcon />}
                    text="My Account"
                  />
                </div>
                <div
                onClick={() => {setPopup(true); freeaptcheck(); setActive(false);
                  setSecondaryMenu(false)}}
                className={`${Number(freeappt.remaining) >= 1 ? "cursor-pointer" : "pointer-events-none text-slate-400"} `}
              >
                <SidebarOption
                  iconName={<NoteAltIcon />}
                  text="Free Appointments"
                />
              </div>
              </>
            )}
          </div>
        </div>

        {/* Mobile Main Menu Ends */}
      <button onClick={() => setSidebar(!sidebar)} onMouseEnter={() => setPlaceholder({open :"Open Sidebar"})} onMouseLeave={() => setPlaceholder({open :""})} className={`${pathname.includes("videoCall") && "pointer-events-none"} absolute left-5 top-10 hover:scale-110 transition-all duration-300`}>
        <span className={`${!sidebar && "hidden"}`}><SortIcon sx={{fontSize:"35px"}}/></span>
        <p className={`bg-slate-700 text-white text-xs absolute -top-3 -right-16 p-1 rounded-md ${!sidebar && "hidden"} ${placeholder.open === "" && "hidden"}`}>{placeholder.open}</p></button>
        
        <div className={`hidden md:hidden lp:block lg:block md:w-72 md:pl-8 lp:pl-3 transition-all duration-300 ${sidebar && "scale-0 md:w-0"}`}>
          <div className="flex justify-between items-center mb-8 relative">
          <img
            onClick={hanldeLogoRoute}
            className="w-40 cursor-pointer"
            src={Logo}
            alt="logo"
          />
          <p className={`bg-slate-700 text-white text-xs absolute -top-3 -right-16 p-1 rounded-md ${placeholder.close === "" && "hidden"}`}>{placeholder.close}</p>
          <span onClick={() => setSidebar(!sidebar)} onMouseEnter={() => setPlaceholder({close :"Close Sidebar"})} onMouseLeave={() => setPlaceholder({close :""})} className={`cursor-pointer hover:scale-110 transition-all duration-300`}><SegmentIcon sx={{fontSize:"30px"}}/></span>
          </div>
          
          {token === null ? (
            <></>
          ) : (
            <>
            <div onClick={handleMainPage} className="cursor-pointer">
            <SidebarOption
              route="/main"
              iconName={<HomeIcon />}
              text="Dashboard"
            />
          </div>
              <div
                onClick={() => handleRoutes("/myAppointments")}
                className="cursor-pointer"
              >
                <SidebarOption
                  route="/myAppointments"
                  iconName={<CalendarMonthIcon />}
                  text="My Appointment"
                />
              </div>
              <div onClick={handleDropdown} className="cursor-pointer">
                <div
                  className={
                    dropdown == true
                      ? "group flex mt-2 w-56 py-3 items-center px-2 bg-white drop-shadow-md rounded-lg"
                      : "group flex mt-2 w-56 py-3 items-center px-2"
                  }
                >
                  <div
                    className={
                      dropdown == true
                        ? "flex items-center justify-center text-white rounded-xl bg-purple w-8 h-8"
                        : "flex items-center justify-center text-purple rounded-xl bg-white w-8 h-8"
                    }
                  >
                    <FavoriteBorderIcon />
                  </div>
                  <p className="ml-3 font-normal">My Health</p>
                  <p className="ml-16">
                    <KeyboardArrowDownIcon />
                  </p>
                </div>
              </div>
              {dropdown ? (
                <>
                  <div>
                    <div
                      onClick={() => handleRoutes("/myVitals")}
                      className="cursor-pointer"
                    >
                      <SidebarOption
                        route="/myVitals"
                        iconName={<VolunteerActivismIcon />}
                        text="My Vitals"
                      />
                    </div>
                    <div
                      onClick={() => handleRoutes("/healthRecord")}
                      className="cursor-pointer"
                    >
                      <SidebarOption
                        route="/healthRecord"
                        iconName={<MedicalServicesIcon />}
                        text="Upload Report"
                      />
                    </div>
                    <div
                      onClick={() => handleRoutes("/favDoc")}
                      className="cursor-pointer"
                    >
                      <SidebarOption
                        route="/favDoc"
                        iconName={<FavoriteIcon />}
                        text="Favorite Doctors"
                      />
                    </div>
                    <div
                      onClick={() => handleRoutes("/docChat")}
                      className="cursor-pointer"
                    >
                      <SidebarOption
                        route="/doctChat"
                        iconName={<ChatIcon />}
                        text="Chat with Doctor"
                      />
                    </div>
                    <div
                      onClick={() => handleRoutes("/ambulance")}
                      className="cursor-pointer"
                    >
                      <SidebarOption
                        route="/ambulance"
                        iconName={<img src={ambu2} className="w-6" alt="" />}
                        text="Ambulance"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div
                onClick={() => handleRoutes("/profile")}
                className="cursor-pointer"
              >
                <SidebarOption
                  route="/profile"
                  iconName={<PermIdentityIcon />}
                  text="My Account"
                />
              </div>
              <div
                onClick={() => {setPopup(true); freeaptcheck()}}
                className={`${Number(freeappt.remaining) >= 1 ? "cursor-pointer" : "pointer-events-none hidden text-slate-400"} `}
              >
                <SidebarOption
                  iconName={<NoteAltIcon />}
                  text="Free Appointments"
                />
              </div>
            </>
          )}
        </div>
        <div className="lp:ml-4 w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
